import * as React from "react";
import {
  Box,
  Link,
  Modal,
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Info, Email, ExpandMore, LinkedIn } from "@mui/icons-material/";
import { GitIcon, MatrixIcon, SimpleXIcon } from "./CustomIcons";

function AboutAccordion() {
  return (
    <Box alignSelf="center">
      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />} id="panel1a-header">
          <Typography>
            <Email />
             email
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Link
            href="mailto:julius@0124816.xyz"
            underline="hover"
            color="text.primary"
          >
            julius@0124816.xyz
          </Link>
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />} id="panel2a-header">
          <LinkedIn /> <Typography>LinkedIn</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Link
            href="www.linkedin.com/in/julius0124816"
            underline="hover"
            color="text.primary"
          >
            www.linkedin.com/in/julius0124816
          </Link>
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />} id="panel3a-header">
          <GitIcon /> <Typography>git</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Link
            href="https://git.0124816.xyz/julius"
            underline="hover"
            color="text.primary"
          >
            git.0124816.xyz/julius
          </Link>
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />} id="panel4a-header">
          <SimpleXIcon sx={{ pr: "4px" }} /> 
          <Typography>SimpleX chat</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Link href="https://simplex.chat/contact#/?v=1-2&smp=smp%3A%2F%2FSkIkI6EPd2D63F4xFKfHk7I1UGZVNn6k1QWZ5rcyr6w%3D%40smp9.simplex.im%2F3T80waXnDQJIWfqBUWer72OoObOY7nro%23%2F%3Fv%3D1-2%26dh%3DMCowBQYDK2VuAyEAdWdD83VYKi3doKCIkB8K7z7h7wALVLs11utAcrGM_jc%253D%26srv%3Djssqzccmrcws6bhmn77vgmhfjmhwlyr3u7puw4erkyoosywgl67slqqd.onion">
            <Box maxWidth="300px" sx={{ width: "96vw", m: "0 auto" }}>
              <img
                src="simplex.chat.png"
                alt="simpleX chat address"
                width="300px"
              />
            </Box>
          </Link>
        </AccordionDetails>
      </Accordion>

      <Accordion disableGutters>
        <AccordionSummary expandIcon={<ExpandMore />} id="panel3a-header">
          <MatrixIcon /> <Typography>matrix</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Link
            href="https://matrix.to/#/@julius:matrix.0124816.xyz"
            underline="hover"
            color="text.primary"
          >
          @julius:matrix.0124816.xyz
          </Link>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default function AboutBackdrop() {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box alignSelf="center" sx={{ ml: 1 }}>
      <IconButton onClick={handleOpen} color="inherit" sx={{ ml: 1 }}>
        <Info />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <AboutAccordion />
      </Modal>
    </Box>
  );
}
